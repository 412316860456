import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", {
    attrs: {
      "after-close": _vm.afterModalClose,
      "title": "Edit Mapping",
      "width": 520
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function() {
        return [_c("a-button", {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v("Cancel")]), _c("a-button", {
          attrs: {
            "ghost": "",
            "type": _vm.toggleBtnType
          },
          on: {
            "click": _vm.onToggleActiveClick
          }
        }, [_vm._v(_vm._s(_vm.toggleBtnText))]), _c("a-button", {
          attrs: {
            "loading": _vm.isLoading,
            "type": "primary"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v("Save")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.visible,
      callback: function($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-row", {
    attrs: {
      "gutter": 24
    }
  }, [_c("a-col", {
    staticClass: "pb-3",
    attrs: {
      "span": 24
    }
  }, [_c("select-input", {
    attrs: {
      "all-in-value": "",
      "label": "Map Type",
      "reference": "grower.common.grade-map-levels",
      "source": "id",
      "source-label": "tableName",
      "value": _vm.entity.mapLevelId,
      "disabled": true
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeMapLevelId", _vm.entity.mapLevelId);
      }
    }
  })], 1), _c("a-col", {
    staticClass: "pb-3",
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "all-in-value": "",
      "label": "Crop Code",
      "reference": "grower.common.crops",
      "source": "id",
      "source-label": _vm.genCropCodeLabel,
      "value": _vm.cropCodeId,
      "disabled": true
    },
    on: {
      "change": _vm.onCropCodeChange
    }
  })], 1), _c("a-col", {
    staticClass: "pb-3",
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.mapCodes,
      "label": "Map Code",
      "source": "mapCode",
      "source-label": "description",
      "value": _vm.entity.mapCode,
      "disabled": true
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("mapCode", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.gradeCodes,
      "label": "Grade Code",
      "source": "id",
      "source-label": "description",
      "value": _vm.entity.gradeId,
      "disabled": true
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("gradeId", $event);
      }
    }
  })], 1), _c("a-col", {
    attrs: {
      "span": 12
    }
  }, [_c("select-input", {
    attrs: {
      "data-source": _vm.bpcsGradeCodes,
      "label": "BPCS Grade Code",
      "source": "id",
      "source-label": "description",
      "value": _vm.entity.bpcsGradeId
    },
    on: {
      "change": function($event) {
        return _vm.storeValue("bpcsGradeId", $event);
      }
    }
  })], 1)], 1)], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditGradeMap",
  inject: ["resourceProps"],
  data() {
    const [
      gradeMapProps,
      mapCodeProps,
      gradeProps,
      bpcsGradeProps,
      cropProps
    ] = this.resourceProps;
    return {
      bpcsGradeProps,
      cropCode: null,
      cropCodeId: void 0,
      cropProps,
      gradeMapProps,
      gradeProps,
      isLoading: false,
      mapCodeProps,
      mapLevel: null,
      disabled: true,
      visible: false
    };
  },
  computed: {
    bpcsGradeCodes() {
      return this.bpcsGradeProps.crud.getList();
    },
    cropList() {
      return this.cropProps.crud.getList();
    },
    entity() {
      return this.gradeMapProps.crud.getEntity() || {};
    },
    gradeCodes() {
      return this.gradeProps.crud.getList();
    },
    mapCodes() {
      return this.mapCodeProps.crud.getList();
    },
    toggleBtnText() {
      return this._.get(this.entity, "isInactive") ? "Activate" : "Deactivate";
    },
    toggleBtnType() {
      return this._.get(this.entity, "isInactive") ? "primary" : "danger";
    }
  },
  watch: {
    cropCode(newVal) {
      if (this.mapType) {
        this.mapCodeProps.crud.fetchList(`${this.mapCodeProps.apiUrl}/grower/common/map-codes?mapLevel=${this.mapType}&cropCode=${newVal}`, void 0, false);
      }
      this.gradeProps.crud.fetchList(`${this.gradeProps.apiUrl}/grower/common/grades-for-grade-map?cropCode=${newVal}`, void 0, false);
      this.bpcsGradeProps.crud.fetchList(`${this.bpcsGradeProps.apiUrl}/grower/common/bpcs-grades-for-grade-map?cropCode=${newVal}`, void 0, false);
    },
    cropList(newVal) {
      if (!newVal.length)
        return;
      const crop = newVal.find((crop2) => crop2.code = this.entity.cropCode);
      this.cropCodeId = crop.id;
    },
    mapLevel(newVal) {
      if (this.cropCode) {
        this.mapCodeProps.crud.fetchList(`${this.mapCodeProps.apiUrl}/grower/common/map-codes?mapLevel=${newVal}&cropCode=${this.cropCode}`, void 0, false);
      }
    }
  },
  created() {
    if (!this.entity)
      this.$router.push(this.gradeMapProps.redirectRoute);
    this.mapLevel = this.entity.mapLevel;
    this.cropCode = this.entity.cropCode;
    Object.entries(this.entity).forEach(([key, value]) => this.storeValue(key, value));
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    genCropCodeLabel(option) {
      return `${option.code} - ${option.name}`;
    },
    storeValue(resourceKey, value) {
      this.gradeMapProps.crud.updateEntity("editForm", {
        resourceKey,
        value
      });
    },
    async submit() {
      this.isLoading = true;
      await this.gradeMapProps.crud.submitEntity("replace");
      this.isLoading = false;
      this.visible = false;
    },
    async toggleActive() {
      const { crud, resourceName } = this.gradeMapProps;
      await crud.submitPartialEntity({
        resourceName,
        id: this.entity.id,
        data: [
          {
            op: "replace",
            path: "/IsInactive",
            value: !this.entity.isInactive
          }
        ]
      });
      this.visible = false;
    },
    afterModalClose() {
      this.$router.push(this.gradeMapProps.redirectRoute);
    },
    onCropCodeChange({ code }) {
      this.cropCode = code;
    },
    onMapTypeChange(value) {
      this.mapLevel = value.mapLevel;
      this.storeValue("gradeMapLevelId", value.id);
    },
    onToggleActiveClick() {
      let icon, okType, title;
      if (this.entity.isInactive) {
        title = "Do you want to Activate this item?";
        icon = "eye";
        okType = "primary";
      } else {
        title = "Do you want to Deactivate this item?";
        icon = "eye-invisible";
        okType = "danger";
      }
      this.$confirm({
        class: "list__modal-confirm",
        icon,
        title,
        cancelText: "No",
        okText: "Yes",
        okType,
        onOk: this.toggleActive
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditGradeMap = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-grade-map"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.grade-map-levels",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": ["grower.grade-maps", "grower.common.map-codes", "grower.common.grades-for-grade-map", "grower.common.bpcs-grades-for-grade-map", "grower.common.crops"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/grade-maps"],
      "resource-id-name": ["id", "mapCode", "id", "id", "id"]
    }
  }, [_c("edit-grade-map")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditGradeMap
  },
  data() {
    return {
      EditGradeMap,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
